import axios from "@/config/http.js";

export const API = {
  // 数据字典首页数据
  async pageIndex(params) {
    return await axios.get("/dictType/page", { params });
  },
  // 根据字典类型ID获取详细信息
  async detailData(params) {
    return await axios.get("/dictType/detail/listById", { params });
  },
  // 保存字典类型
  async addDataType(params) {
    return await axios.put("/dictType", params);
  },
  // 保存字典数据
  async addDictionData(params) {
    return await axios.put("/dictData", params);
  },
  // 删除字典数据
  async deleteDictionData(dictDataId) {
    return await axios.delete(`/dictData?dictDataId=${dictDataId}`);
  },
};
