<template>
  <div class="data-dictionary-container">
    <div class="top-container">
      <a-row>
        <a-form-model layout="inline" style="display: flex">
          <a-form-model-item label="字典名称">
            <a-input
              placeholder="请输入"
              v-model.trim="queryFrom.dictName"
              @keyup.enter="queryBtn"
            ></a-input>
          </a-form-model-item>
          <a-form-model-item label="状态">
            <a-select
              show-search
              placeholder="请选择"
              :getPopupContainer="
                (triggerNode) => handleDislocation(triggerNode)
              "
              :default-active-first-option="false"
              :style="{ width: '28.5rem' }"
              :not-found-content="null"
              :filterOption="filterOpt"
              v-model="queryFrom.status"
            >
              <a-select-option
                v-for="(item, i) in statusList"
                :value="item"
                :key="i"
              >
                {{ item }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <div class="right">
            <a-button type="reload" icon="redo" @click="handleReset"
              >重置</a-button
            >
            <a-button type="primary" icon="search" @click="queryBtn"
              >查询</a-button
            >
          </div>
        </a-form-model>
      </a-row>
    </div>
    <div class="bottom-container">
      <a-button class="mb2" type="primary" @click="handleAdd">新增</a-button>
      <a-table
        :columns="columns"
        :data-source="data"
        :key="data.key"
        :loading="loading"
        :scroll="{ y: 400 }"
        :pagination="pagination"
      >
        <template slot="dictName" slot-scope="text, record">
          <span v-show="!record.modify" :title="text">{{ text }}</span>
          <a-form-model v-show="record.modify" :model="record" :rules="Trules">
            <a-form-model-item prop="dictName">
              <a-input
                :disabled="record.dictionNameVisible"
                v-model.trim="record.dictName"
              ></a-input>
            </a-form-model-item>
          </a-form-model>
        </template>
        <template slot="status" slot-scope="text, record">
          <span v-show="!record.modify">{{ text }}</span>
          <a-form-model v-show="record.modify" :model="record" :rules="Trules">
            <a-form-model-item prop="status">
              <a-select
                show-search
                placeholder="请选择"
                :default-active-first-option="false"
                :style="{ width: '28.5rem' }"
                :not-found-content="null"
                v-model="record.status"
              >
                <a-select-option
                  v-for="(item, i) in statusList"
                  :value="item"
                  :key="i"
                >
                  {{ item }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-form-model>
        </template>
        <template slot="dictDescribe" slot-scope="text, record">
          <span v-show="!record.modify" :title="text">{{ text }}</span>
          <a-form-model v-show="record.modify" :model="record" :rules="Trules">
            <a-form-model-item prop="dictDescribe">
              <a-input v-model="record.dictDescribe"></a-input>
            </a-form-model-item>
          </a-form-model>
        </template>
        <template slot="operation" slot-scope="text, record, index">
          <span
            class="hover"
            :style="{ color: `${getColor('主题')}` }"
            v-show="record.modify"
            @click="handleOk(record, index)"
            >保存</span
          >
          <span
            class="hover"
            :style="{ color: `${getColor('主题')}` }"
            @click="handleModify(record, index)"
            v-show="!record.modify"
            >修改</span
          >
          <span
            class="hover"
            :style="{ color: `${getColor('主题')}` }"
            @click="openModal(record.dictId)"
            >详情</span
          >
        </template>
      </a-table>
    </div>
    <DetailModal
      :modalVisible="modalVisible"
      v-if="modalVisible"
      :config="configFrom"
    />
  </div>
</template>

<script>
const columns = [
  {
    title: "字典名称",
    ellipsis: true,
    dataIndex: "dictName",
    scopedSlots: { customRender: "dictName" },
  },
  {
    title: "状态",
    ellipsis: true,
    dataIndex: "status",
    scopedSlots: { customRender: "status" },
  },
  {
    title: "说明",
    ellipsis: true,
    dataIndex: "dictDescribe",
    scopedSlots: { customRender: "dictDescribe" },
  },
  {
    title: "操作",
    ellipsis: true,
    dataIndex: "operation",
    scopedSlots: { customRender: "operation" },
  },
];

import mixins from "@/mixins/mixins.js";
import pagination from "@/mixins/Pagination.js";
import { filterOpt } from "@/utils/utils";
import { getColor } from "@/utils/color";
import DetailModal from "./detailModal.vue";
import { API } from "@/apix/dataDictionary.js";
import { tips } from "@/utils/tips";
import { DictionaryRule } from "@/utils/rulex";

export default {
  mixins: [pagination, mixins],
  components: {
    DetailModal,
  },
  data() {
    return {
      Trules: DictionaryRule,
      columns,
      data: [],
      loading: false,
      statusList: ["启用", "禁用"],
      modalVisible: false,
      queryFrom: {
        status: null, // 字典状态
        dictName: null, // 字典名称
      },
      configFrom: {
        id: null,
      },
      dictionNameVisible: false, // 字典名称禁用
    };
  },
  methods: {
    filterOpt,
    getColor,
    handleOk(record) {
      if (!record.dictName) {
        this.$message.error("请输入字典名称");
        return;
      } else if (!record.status) {
        this.$message.error("请输入字典状态");
        return;
      } else {
        API.addDataType(record).then((res) => {
          tips(res, "保存");
          if (res.success) {
            record.modify = false;
            this.getSourceData();
          }
        });
      }
    },
    handleModify(record) {
      record.dictionNameVisible = true;
      record.modify = true;
    },
    openModal(id) {
      if (!id) {
        this.$message.error("请保存后点击详情");
      } else {
        this.configFrom.id = id;
        this.modalVisible = true;
      }
    },
    closeModal() {
      this.modalVisible = false;
    },
    // 增加表格项
    handleAdd() {
      const newData = {
        modify: true,
      };
      this.data = [newData, ...this.data];
    },
    // 删除表格项
    // onDelete(i) {
    //   const dataSource = [...this.data];
    //   this.data = dataSource.filter((item, index) => index !== i);
    // },
    // 获取首页所有字典数据
    getSourceData() {
      let detail = {
        page: this.pagination.current,
        size: this.pagination.pageSize,
        ...this.queryFrom,
      };
      this.loading = true;
      API.pageIndex(detail).then((res) => {
        this.pagination.total = Number(res.data.total);
        this.data = res.data.records.map((i) => {
          i.modify = false;
          i.dictionNameVisible = false;
          i.key = i.id;
          return i;
        });
        this.loading = false;
      });
    },
    // 重置按钮
    handleReset() {
      this.pagination.current = 1;
      Object.keys(this.queryFrom).forEach((key) => {
        this.queryFrom[key] = null;
      });
      this.getSourceData();
    },
  },
  created() {
    this.getSourceData();
  },
};
</script>

<style lang="scss" scoped>
.top-container {
  height: 10rem;
}
.ant-input {
  width: 28.5rem;
}
.right {
  flex: 1;
  white-space: nowrap;
  text-align: right;
  button {
    margin-right: 2rem;
  }
}
.bottom-container {
  min-height: 83%;
}

.hover {
  margin-right: 2rem;
}

/deep/ .ant-form-item {
  margin-bottom: 0;
}
</style>
