<template>
  <div class="detail-modal-container">
    <a-modal
      :visible="modalVisible"
      class="modal-style"
      @cancel="cancel"
      @ok="handleOk"
      okText="提交"
      cancelText="返回"
      :maskClosable="false"
      :width="1139"
    >
      <div>详情</div>
      <a-divider />
      <a-button type="primary" class="mb2" @click="handleAdd"> 新增 </a-button>
      <a-table
        :columns="columns"
        :data-source="data"
        :key="data.key"
        :customRow="customRow"
        :loading="loading"
        :scroll="{ y: 400 }"
        :pagination="false"
      >
        <template slot="dictCode" slot-scope="text, record">
          <span v-show="!record.modify" :title="text">{{ text }}</span>
          <a-form-model v-show="record.modify" :model="record" :rules="Trules">
            <a-form-model-item prop="dictCode">
              <a-input v-model="record.dictCode"></a-input>
            </a-form-model-item>
          </a-form-model>
        </template>
        <template slot="dictValue" slot-scope="text, record">
          <span v-show="!record.modify" :title="text">{{ text }}</span>
          <a-form-model v-show="record.modify" :model="record" :rules="Trules">
            <a-form-model-item prop="dictValue">
              <a-input v-model="record.dictValue"></a-input>
            </a-form-model-item>
          </a-form-model>
        </template>
        <template slot="operation" slot-scope="text, record, index">
          <span
            class="hover"
            :style="{ color: `${getColor('主题')}` }"
            @click="onDelete(index, record)"
            >删除</span
          >
        </template>
      </a-table>
    </a-modal>
  </div>
</template>

<script>
const columns = [
  {
    title: "编码(key)",
    ellipsis: true,
    dataIndex: "dictCode",
    scopedSlots: { customRender: "dictCode" },
  },
  {
    title: "标签(value)",
    ellipsis: true,
    dataIndex: "dictValue",
    scopedSlots: { customRender: "dictValue" },
  },
  {
    title: "操作",
    ellipsis: true,
    scopedSlots: { customRender: "operation" },
        width: 100,
  },
];
import mixins from "@/mixins/mixins.js";
import { getColor } from "@/utils/color";
import pagination from "@/mixins/Pagination.js";
import { API } from "@/apix/dataDictionary.js";
import { tips } from "../../utils/tips";
import { DictionaryRule } from "@/utils/rulex";

export default {
  mixins: [mixins, pagination],
  props: {
    modalVisible: {
      type: Boolean,
      default: false,
    },
    config: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      Trules: DictionaryRule,
      columns,
      data: [],
      loading: false,
    };
  },
  methods: {
    getColor,
    handleOk() {
      this.data.forEach((item) => {
        if (!item.dictCode) {
          this.$message.error("请输入字典编码");
          throw new Error("请输入字典编码");
        } else if (!item.dictValue) {
          this.$message.error("请输入字典标签值");
          throw new Error("请输入字典标签值");
        }
      });
      let result = this.data.map((item) => {
        item.dictId = this.config.id;
        return item;
      });
      let self = this;
      this.$confirm({
        title: "是否确定提交数据?",
        cancelText: "取消",
        okText: "确定",
        onOk() {
          self.loading = true;
          API.addDictionData(result).then((res) => {
            tips(res, "提交");
            if (res.success) {
              self.cancel();
              self.getSourceData(this.config.id);
            }
            self.loading = false;
          });
        },
      });
    },
    // 增加表格项
    handleAdd() {
      const newData = {
        modify: true,
      };
      this.data = [...this.data, newData];
    },
    // 删除表格项
    onDelete(i, record) {
      if (!record.dictId) {
        const dataSource = [...this.data];
        this.data = dataSource.filter((item, index) => index !== i);
      } else {
        if (this.data.length < 2) {
          this.pagination.current--;
        }
        let self = this;
        this.$confirm({
          title: "是否确定删除数据?",
          content: "删除后数据无法恢复",
          cancelText: "取消",
          okText: "确定",
          onOk() {
            self.loading = true;
            API.deleteDictionData(record.dictDataId).then((res) => {
              tips(res, "删除");
              if (res.success) {
                self.getSourceData(self.config.id);
              }
              self.loading = false;
            });
          },
        });
      }
    },
    getSourceData(id) {
      let detail = {
        dictId: id,
      };
      this.loading = true;
      API.detailData(detail).then((res) => {
        this.data = res.data.map((i) => {
          i.modify = false;
          i.key = i.id;
          return i;
        });
        this.loading = false;
      });
    },
  },
  created() {
    this.getSourceData(this.config.id);
  },
};
</script>

<style lang="scss" scoped>
/deep/ .ant-form-item {
  margin-bottom: 0;
}
</style>
